import styled from 'styled-components'
import { onNotLarge } from '../../styles/mediaQueries'


export const HeaderSection = styled.header`
  background-color: #fff;
  border-bottom: 1px #000;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  ${onNotLarge} {
    padding: 12px 20px;
  }
`

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
`

export const Logo = styled.div`
  align-self: center;

  img {
    width: 150px;
    object-fit: cover;
    ${onNotLarge} {
      width: 94px;
    }
  }
`

export const Login = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #666e7b;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  ${onNotLarge} {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
  }
`

export const ProfileImg = styled.div`
  width: 40px;
  height: 40px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  ${onNotLarge} {
    width: 32px;
  height: 32px;
  }
`