import styled from 'styled-components'
import kakaoLogo from '../../../assets/logo_kakao.png'
import { onNotLarge } from '../../../styles/mediaQueries'

const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 52px;
  background-color: #fee500;
  border: none;
  border-radius: 60px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  ${onNotLarge}{
    width: 100%;
  }
 
`

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

export const KakaoLoginButton = () => {

  const loginWithKakao = () => {
    window.location.href = 'https://kauth.kakao.com/oauth/authorize?client_id=bcb1a7276f6c5cb45dbeb4805394f520&redirect_uri=https://dev.nurpass.me/auth/kakao/callback&response_type=code';
  };

  return (
    <LoginButton onClick={loginWithKakao}>
      <Logo src={kakaoLogo} alt="kakao" />
      카카오로 시작하기
    </LoginButton>
  )
}
