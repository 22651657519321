import { KakaoLoginButton } from './components/LoginButton'
import styled from 'styled-components'
import { onNotLarge } from '../../styles/mediaQueries'

const LoginContainer = styled.section`
  padding: 140px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  ${onNotLarge}{
    align-items: baseline;
  }
`

const LoginWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`

const Title = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin: 16px 0px 50px;
  line-height: 36px;
`

function LoginPage() {
  return (
    <LoginContainer>
      <LoginWrapper>
        <Title>간호사 커리어,<br/>
        널패스로 시작하세요!
        </Title>
        <KakaoLoginButton />
      </LoginWrapper>
    </LoginContainer>
  )
}

export default LoginPage
