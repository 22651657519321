import * as S from './Header.style'
import logo from '../../assets/logo_nurpass.svg'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'

function Header() {
  const navigate = useNavigate()
  const { user } = useAuth();

  const onClickPayment = () => {
    window.open('https://nurpass.im/login_page', '_blank')
  }

  return (
    <S.HeaderSection>
      <S.HeaderContainer>
        <S.Logo>
          <img src={logo} alt="logo" onClick={() => navigate('/')} />
        </S.Logo>
        {user ? (
          <S.ProfileImg>
            <img src={user.profile_image} alt={user.nickname} />
          </S.ProfileImg>
        ) : (
          <S.Login onClick={() => navigate('/signin')}>로그인</S.Login>
        )}
        
      </S.HeaderContainer>
    </S.HeaderSection>
  )
}

export default Header
