import './App.css'
import Header from './components/Header'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AuthProvider } from '../src/context/AuthContext';
import LandingPage from './views/LandingPage'
import LoginPage from './views/LoginPage'
import KakaoRedirectHandler from './components/KakaoRedirectHandler'
import MetaTag from './components/MetaTag'

function App() {
  return (
    <AuthProvider>
      {/* <MetaTag /> */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/auth/kakao/callback" element={<KakaoRedirectHandler />} />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App
