import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

function KakaoRedirectHandler (){
  const { login } = useAuth();
  const navigate = useNavigate();
  

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      axios
        .post('https://dev-api.nurpass.me/auth/kakao/callback', { code },{
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        })
        .then((response) => {
          const { access_token, refresh_token, user } = response.data;
          login(user, { access_token, refresh_token });
          navigate('/');
        })
        .catch((error) => {
          console.error('Login error:', error);
          navigate('/signin');
        });
    } else {
      navigate('/signin');
    }
  }, [navigate, login]);


  return (
    <div>
      로그인 처리 중...
    </div>
  );
};

export default KakaoRedirectHandler